import '@/main.scss';

import { createPinia } from 'pinia';
import { createApp } from 'vue';

import App from './App.vue';
import { initialize } from './auth';
import { router } from './plugins/router';
import { vuetify } from './plugins/vuetify';

const main = async () => {
  const app = createApp(App);
  //await initialize();

  app.use(router);
  app.use(vuetify);
  app.use(createPinia());

  app.mount('#app');
};

main();
