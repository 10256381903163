import { createVuetify } from 'vuetify';
import type { VTextField } from 'vuetify/components';
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';

const vTextFieldDefaults: Partial<InstanceType<typeof VTextField>> = { bgColor: '#f1f2eb' };

export const vuetify = createVuetify({
  theme: {
    defaultTheme: 'dark',
    themes: {
      dark: {
        colors: {
          background: '#FFFFFF',
          surface: '#FFFFFF',
          primary: '#A32900',
          secondary: '#FB8B24',
          info: '#3C5500',
          success: '#7BEDA7',
          warning: '#EEBA0B',
          error: '#EF514B',
          casual: '#000000',
        },
      },
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  defaults: {
    VTextField: vTextFieldDefaults,
  },
});
