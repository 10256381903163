type Mode = 'staging' | 'production';

interface ConfigParameters {
  mode: Mode;
  toolbarExtraText?: string;
  authUrl: string;
}

interface ToolConfig {
  isProduction: boolean;
}

const notUndefinedVite = (name: string, value: string | undefined): string => {
  if (value === undefined) {
    throw new Error(`Environment variable ${name} must be defined`);
  }
  return value;
};

const stagingOrProductionVite = (name: string, value: string | undefined): Mode => {
  value = notUndefinedVite(name, value);
  if (value !== 'production' && value !== 'staging') {
    throw new Error(`Environment variable ${name} must be production or staging, but was: ${value}`);
  }
  return value;
};

const generateConfigParametersVite = (envVars: ImportMetaEnv): ConfigParameters => ({
  authUrl: notUndefinedVite('VITE_AUTH_URL', envVars.VITE_AUTH_URL),
  mode: stagingOrProductionVite('VITE_STAGING_OR_PRODUCTION', envVars.VITE_STAGING_OR_PRODUCTION),
  toolbarExtraText: envVars.VITE_TOOLBAR_EXTRA_TEXT,
});

class Config implements ConfigParameters, ToolConfig {
  readonly authUrl!: ConfigParameters['authUrl'];
  readonly mode!: ConfigParameters['mode'];
  readonly toolbarExtraText: ConfigParameters['toolbarExtraText'];
  // ToolConfig
  readonly isProduction!: ToolConfig['isProduction'];

  constructor(params: ConfigParameters) {
    const isProduction = params.mode === 'production';

    Object.assign(this, {
      ...params,
      isProduction,
    } satisfies ConfigParameters & ToolConfig);
  }
}

const configVite = (envVars: ImportMetaEnv): Config => new Config(generateConfigParametersVite(envVars));
export const appConfig = configVite(import.meta.env);
