import { createClient, type IAuthClient, type IAuthOptions, type User } from '@propelauth/javascript';

import { appConfig } from './app-config';

let propelAuthInstance: IAuthClient;

const getPropelAuthConfig = (): IAuthOptions => {
  return {
    authUrl: appConfig.authUrl,
    enableBackgroundTokenRefresh: true,
  };
};

const constructPropelAuthInstance = (): IAuthClient => {
  const config = getPropelAuthConfig();
  propelAuthInstance = createClient(config);

  return propelAuthInstance;
};

export const getPropelAuthInstance = (): IAuthClient => {
  if (!propelAuthInstance) {
    throw Error('PropelAuth instance not initialized');
  }
  return propelAuthInstance;
};

export const initialize = async (): Promise<void> => {
  constructPropelAuthInstance();

  // redirect to login on prod to prevent access
  const userInfo = await getUserInfo();
  if (userInfo === null && appConfig.isProduction) {
    await redirectToLoginPage();
  }
};

export const getUserInfo = async (): Promise<User | null> => {
  const propelAuthInstance = getPropelAuthInstance();
  const authInfo = await propelAuthInstance.getAuthenticationInfoOrNull();

  if (authInfo) {
    // eslint-disable-next-line no-console
    console.log('User is logged in as', authInfo.user.email);
  } else {
    console.warn('User is not logged in');
    return null;
  }

  return authInfo.user;
};

export const redirectToLoginPage = async () => {
  const instance = getPropelAuthInstance();

  instance.redirectToLoginPage({
    postLoginRedirectUrl: window.location.href,
  });
};

export const redirectToSignupPage = async () => {
  const instance = getPropelAuthInstance();

  instance.redirectToSignupPage({
    postSignupRedirectUrl: window.location.href,
    userSignupQueryParameters: {
      ref: 'my-cool-blog-post',
    },
  });
};

export const logout = async () => {
  const instance = getPropelAuthInstance();
  await instance.logout(true);
};
