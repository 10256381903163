import { createRouter, createWebHashHistory, type RouteComponent, type RouteRecordRaw } from 'vue-router';

const ExploreView = (): Promise<RouteComponent> => import('@/views/ExploreView.vue');
const HomeView = (): Promise<RouteComponent> => import('@/views/HomeView.vue');

const routes: readonly RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
  },
  {
    path: '/explore',
    name: 'Explore',
    component: ExploreView,
  },
];

export const router = createRouter({
  routes,
  history: createWebHashHistory(),
});
