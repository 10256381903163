<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script lang="ts" setup>
import { onBeforeMount } from 'vue';

import { useApplicationStore } from './stores/application';

const applicationStore = useApplicationStore();

onBeforeMount(async () => {
  //await applicationStore.loadUserInfo();
});
</script>
