import { defineStore } from 'pinia';
import { ref } from 'vue';

import { getUserInfo, logout, redirectToLoginPage } from '@/auth';

const CATEGORIES = {
  Architecture: 'Architectural Design',
  Interior: 'Interior Design',
  Landscape: 'Landscape Planning',
  Exterior: 'Exterior Design',
  Product: 'Product Development',
  Graphics: 'Graphics Design',
  Marketing: 'Sales',
  'Web App': 'UX Design',
  Gaming: 'Game Design',
  Fashion: 'Fashion Trends',
};

type EskizzUser = { email: string; username?: string };

export const useApplicationStore = defineStore('application', () => {
  /* State */
  const loggedInUser = ref<EskizzUser | null>(null);
  const categories = ref(CATEGORIES);
  const currentCategory = ref<null | keyof typeof CATEGORIES>(null);

  const refsState = { loggedInUser, currentCategory, categories };

  /* Getters */

  const getters = {};

  /* Actions */

  const doLogout = async () => {
    await logout();
  };
  const toLoginPage = async () => {
    redirectToLoginPage();
  };
  const loadUserInfo = async (): Promise<EskizzUser | null> => {
    const userInfo = await getUserInfo();

    if (userInfo) {
      loggedInUser.value = { email: userInfo.email, username: userInfo.username };
    }

    return loggedInUser.value;
  };

  const actions = { doLogout, loadUserInfo, toLoginPage };

  return {
    ...refsState,
    ...getters,
    ...actions,
  };
});

export type ApplicationStore = ReturnType<typeof useApplicationStore>;
export type ApplicationStoreState = ApplicationStore['$state'];
